import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LiaStoreSolid } from 'react-icons/lia'; // Import the store icon
import './Stores.css';

const Stores = ({ onStoreSelect }) => {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const url = `${window.env.REACT_APP_BASE_URL}user_stores`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        setStores(res.data.stores);
      } catch (err) {
        console.error('Error fetching stores:', err);
        setError(`Failed to fetch stores. Please try again later. Error: ${err.message}, Details: ${err.response?.data}`);
      }
    };

    fetchStores();
  }, []);

  const handleStoreClick = (storeId) => {
    try {
      localStorage.setItem('storeId', storeId); // Store storeId in localStorage
      onStoreSelect(); // Notify parent component
      navigate(`/restaurants/orders`);
    } catch (err) {
      console.error('Error navigating to settings:', err);
      setError(`Failed to navigate to settings. Error: ${err.message}, Details: ${err.response?.data}`);
    }
  };

  return (
    <div className="stores-container">
      <h2>Stores</h2>
      {error && <p className="error-message">{error}</p>}
      {stores.length > 0 ? (
        <ul className="stores-list">
          {stores.map(store => (
            <li key={store.id} className="store-item" onClick={() => handleStoreClick(store.store_id)}>
              <LiaStoreSolid className="store-icon" />
              <div>
                <h3>{store.name}</h3>
                <p>{store.address}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        !error && <p>No stores found.</p>
      )}
    </div>
  );
};

export default Stores;
