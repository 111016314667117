import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PrinterScan.css';
import { FaEdit, FaTrash } from 'react-icons/fa';


const Tva = () => {
    const [taxes, setTaxes] = useState([]);
    const [error, setError] = useState(null);
    const [newTax, setNewTax] = useState({ name: '', percentage: '' });
    const [editingTax, seteditingTax] = useState(null);
    const [fiscal, setFiscal] = useState('');
    const [editFiscal, setEditFiscal] = useState(false);
    const [newFiscal, setNewFiscal] = useState(fiscal);
    const jwt = localStorage.getItem('jwt');
    const store_id = localStorage.getItem('storeId');
    const base_url = window.env.REACT_APP_BASE_URL;

    const fetchFiscal = async () => {
        try {
          const url = `${base_url}parameter-store/?store_id=${store_id}`;
          const res = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${jwt}`
            },
            withCredentials: true
          });
          setFiscal(res.data.fiscal_number);
        } catch (err) {
          console.error('Error fetching store parameters:', err);
          setError(`Failed to fetch store parameters. Please try again later. Error: ${err.message}, Details: ${err.response?.data}`);
        }
    };

    const fetchTaxes = async () => {
        try {
          const response = await fetch(`${base_url}taxes/${store_id}/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          
          const data = await response.json();
          setTaxes(data);
          if (response.ok) {
            console.log('Taxes:', data.taxes);
          } else {
            console.error('Error fetching taxes:', data.error);
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };      

    useEffect(() => {
        fetchTaxes();
        fetchFiscal();
    }, []);

    const saveTax = async () => {
        setError(null);

        if (!newTax.name || !newTax.percentage) {
            setError('Nom et Pourcentage sont requis.');
            setTimeout(() => setError(''), 1000);
            return;
        }

        try {
            const response = await axios.post(`${base_url}taxes/${store_id}/`,{ ...newTax, store_id });
            setTaxes([...taxes, response.data]);
            setNewTax({ name: '', percentage: '' });

            if (response.ok) {
            console.log('Tax saved:', response.data);
            } else {
            console.error('Error saving tax:', response.data.error);
            }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const editTax = async () => {
        setError(null);

        if (!editingTax.name || !editingTax.percentage) {
            setError('Nom et Pourcentage sont requis.');
            setTimeout(() => setError(''), 1000);
            return;
        }

        try {
            const tax_id = editingTax.id;
            const response = await axios.put(`${base_url}taxes/${store_id}/`,{ ...editingTax, store_id, tax_id });
            setTaxes(prev =>
                prev.map(tax =>
                  tax.id === editingTax.id
                    ? { ...tax, 
                        name: editingTax.name,
                        percentage: editingTax.percentage 
                    }
                    : tax
                )
            );

            seteditingTax(null);

            if (response.ok) {
            console.log('Tax saved:', response.data);
            } else {
            console.error('Error saving tax:', response.data.error);
            }
        } catch (error) {
          console.error('Error:', error);
        }
    }

    const handleDeleteClick = async (id) => {
        try {
            const response = await axios.delete(`${base_url}taxes/${store_id}/`, { data: { tax_id : id } });
            if (response.status==204) {
                setTaxes(taxes.filter(taxe => taxe.id !== id));
            }
        } catch (err) {
            console.error("Échec de la suppression");
        }
    };

    const handleFiscalChange = async () => {
        setEditFiscal(false);
        
        const fiscal_number = newFiscal;
    
        await axios.put(
            `${base_url}parameter-store/`, 
            { store_id, fiscal_number },
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );
        
        // Update the local state as well
        setFiscal(newFiscal);
    };

    // Handle discarding the changes
    const handleDiscard = () => {
        setNewFiscal(fiscal);
        setEditFiscal(false);
    };

    return (
        <div className="orders-container">
            <div class="add-menu">
                <span style={{marginLeft:'2%', marginRight:'50%', fontWeight:'bold'}}>Numéro fiscal</span>
                
                <div className="store-parameter">
                    {editFiscal ? (
                        <>
                            <div className="constraint-form-container">
                                <input
                                    type="text"
                                    value={newFiscal}
                                    onChange={(e) => setNewFiscal(e.target.value)}
                                />
                                <button className="save-button" onClick={handleFiscalChange}>
                                    Ajouter
                                </button>
                                <button className="save-button" onClick={handleDiscard}>
                                    Annuler
                                </button>
                            </div>
                        </>
                    ) : (
                        <div>
                            <span style={{marginLeft:'10px', marginRight: '20px'}}>{fiscal}</span>
                            <FaEdit className="fa-edit" onClick={() => { 
                                setNewFiscal(fiscal); 
                                setEditFiscal(true); 
                            }} />
                        </div>
                    )}
                </div>
            </div>

            <div className="add-menu">
                <input
                    type="text"
                    placeholder="Nom de la TVA"
                    value={newTax.name}
                    onChange={(e) => setNewTax({ ...newTax, name: e.target.value })}
                />
                <input
                    type="number"
                    min={0}
                    placeholder="Pourcentage"
                    value={newTax.percentage}
                    onChange={(e) => setNewTax({ ...newTax, percentage: e.target.value })}
                    required
                />
                %
                <button onClick={saveTax}>
                    Ajouter
                </button>
            </div>

            {error && <p className="errorResult">Erreur : {error}</p>}
            <table className="printer-table">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Pourcentage</th>
                        <th>Aciton</th>
                    </tr>
                </thead>
                <tbody>
                    {taxes.length > 0 ? (
                        taxes.map((tax) => (
                            <tr key={tax.id}>
                                <td>
                                    {editingTax && editingTax.id === tax.id ? (
                                        <input
                                            type="text"
                                            value={editingTax.name}
                                            onChange={(e) => seteditingTax({ ...editingTax, name: e.target.value })}
                                        />
                                    ) : (
                                        tax.name
                                    )}
                                </td>
                                <td>
                                    {editingTax && editingTax.id === tax.id ? (
                                        <input
                                            type="number"
                                            value={editingTax.percentage}
                                            onChange={(e) => seteditingTax({ ...editingTax, percentage: e.target.value })}
                                        />
                                    ) : (
                                        tax.percentage
                                    )}
                                </td>
                                <td>
                                    {editingTax && editingTax.id === tax.id ? (
                                        <div className="constraint-form-container">
                                            <button className="save-button" onClick={editTax}>
                                                Ajouter
                                            </button>
                                            <button className="save-button" onClick={() => seteditingTax(null)}>
                                                Annuler
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <FaEdit
                                                className="fa-edit"
                                                onClick={() => seteditingTax(tax)}
                                            />
                                            <FaTrash
                                                className="fa-trash"
                                                onClick={() => handleDeleteClick(tax.id)}
                                            />
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="no-devices">Aucune TVA trouvée</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Tva;
