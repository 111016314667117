import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Auth from './Pages/Auth/Auth';
import Orders from './Components/Orders/Orders';
import Restaurants from './Pages/Restaurants/Restaurants';
import Categories from './Components/Categories/Categories';
import Supplements from './Components/Supplements/Supplements';
import Ingredients from './Components/Ingredients/Ingredients';
import Menu from './Components/Menu/Menu';
import Settings from './Components/Settings/Settings';
import Navbar from './Components/Navbar/Navbar';
import Sidebar from './Components/Sidebar/Sidebar';
import Stores from './Pages/Stores/Stores';
import ProductMenu from './Components/Menu/ProductMenu';
import Ajouts from './Components/Ajouts/Ajouts';
import IngredientMenu from './Components/Menu/ingredientMenu';
import SupplementMenu from './Components/Menu/supplementMenu';
import axios from 'axios';
import Printer from './Components/Settings/Printer';
import StoreSettings from './Components/Settings/Store';
import Tva from './Components/Settings/tva';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initialize as null to handle loading state
  const [storeSelected, setStoreSelected] = useState(localStorage.getItem('storeId') ? true : false);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Verify the JWT token
      axios.get(`${window.env.REACT_APP_BASE_URL}getUser`)
        .then((response) => {
          if (response.status === 200) {
            setIsAuthenticated(true); // Set authenticated if token is valid
          } else {
            setIsAuthenticated(false);
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
          localStorage.removeItem('jwt'); // Remove invalid or expired token
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleStoreSelect = () => {
    setStoreSelected(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setStoreSelected(false);
    localStorage.removeItem('jwt'); // Remove JWT token from localStorage on logout
    localStorage.removeItem('storeId');
  };

  // Show a loading spinner while the authentication check is in progress
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isAuthenticated && location.pathname !== '/'  && <Navbar onLogout={handleLogout} />}
      {isAuthenticated && <hr />}
      <div className={
        isAuthenticated &&
        storeSelected &&
        (location.pathname !== '/stores' && location.pathname !== '/') 
          ? "app-content authenticated" 
          : "app-content"
      }>
        {isAuthenticated && storeSelected && (location.pathname !== '/stores' && location.pathname !== '/') && <Sidebar />}
        <Routes>
          <Route path="/" element={<Auth onLogin={handleLogin} />} />
          {isAuthenticated ? (
            <>
              <Route path="/stores" element={<Stores onStoreSelect={handleStoreSelect} />} />
              {storeSelected && (
                <>
                  <Route path="/restaurants" element={<Restaurants />} />
                  <Route path="/restaurants/orders" element={<Orders />} />
                  <Route path="/restaurants/categories" element={<Categories />} />
                  <Route path="/restaurants/ajouts" element={<Ajouts />} />
                  <Route path="/restaurants/supplements" element={<Supplements />} />
                  <Route path="/restaurants/ingredients" element={<Ingredients />} />
                  <Route path="/restaurants/menu" element={<Menu />} />
                  <Route path="/restaurants/menu/ProductMenu" element={<ProductMenu />} />
                  <Route path="/restaurants/menu/ProductMenu/ingredients" element={<IngredientMenu />} />
                  <Route path="/restaurants/menu/ProductMenu/supplements" element={<SupplementMenu />} />
                  <Route path="/restaurants/settings" element={<Settings />} />
                  <Route path="/restaurants/settings/store" element={<StoreSettings />} />
                  <Route path="/restaurants/settings/printer" element={<Printer />} />
                  <Route path="/restaurants/settings/tva" element={<Tva />} />
                </>
              )}
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
